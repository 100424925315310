<template lang="pug">
  div
    b-row.m-0.justify-content-center
      b-col.content-header(cols='9')
        .d-flex.align-items-start.w-100.justify-content-between
          h2.content-header-title.float-left.m-0.pr-1.mr-1
            | {{ $route.meta.pageTitle }}
          .content-header-search.mr-1
            h3.m-0 
          .content-header-actions.d-flex
            feather-icon.cursor-pointer(icon='XIcon' size='24' @click='cancel()')
    .content-loader-center.m-0.h-100(v-if='isLoadingUser')
      .text-center.flex-center
        .loading-bg-inner(role='status')
          .loader
            .outer
            .middle
            .inner
        br
        | Chargement du formulaire...
    b-row.content-scrollable-sticky.m-0.justify-content-center(v-else='' style="height: calc(100% - 110px)")
      b-col.content-scrollable-sticky-main.h-100(cols='9')
        validation-observer(ref="formMyAccount")
          b-form.h-100(autocomplete="off" style="overflow-x: hidden;")
            div(id="general" class="my-0")
              b-row(class="my-0")
                b-col(cols="4" class="py-0" class="mb-1")
                  b-form-group(label="Prénom *")
                    validation-provider(#default='{ errors }' name='firstName' rules="required")
                      b-form-input#firstName(v-model='userData.firstName' :state="errors.length > 0 ? false:null")
                      small.text-danger {{ errors[0] }}              
                b-col(cols="4" class="py-0" class="mb-1")
                  b-form-group(label="Nom *")
                    validation-provider(#default='{ errors }' name='lastName' rules="required")
                      b-form-input#lastName(v-model='userData.lastName' :state="errors.length > 0 ? false:null")
                      small.text-danger {{ errors[0] }}              
                b-col(cols="4" class="py-0" class="mb-1")
                  b-form-group(label="Numéro de téléphone")
                    validation-provider(#default='{ errors }' name='phoneNumber')
                      b-form-input#phoneNumber(v-model='userData.phoneNumber')
              b-row(class="my-0")
                b-col(cols="4" class="py-0" class="mb-1")
                  b-form-group(label="Email *")
                    validation-provider(#default='{ errors }' name='email' reules="required|email")
                      b-form-input#email(v-model='userData.email' :state="errors.length > 0 ? false:null")
                      small.text-danger {{ errors[0] }}              
        b-row.my-0
          b-col.py-0(cols='12')
            .d-flex.justify-content-between.mt-2(style='border-top: 1px solid #e2e2e2; padding-top: 0.5rem;')
              div
                b-button(variant='outline-primary' @click='cancel()')
                  feather-icon(icon='ArrowLeftIcon')
                    | Annuler
              b-button(variant='primary' @click='updateUserEvent')
                feather-icon(icon='SaveIcon' class="mr-1")
                | Sauvegarder
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { BButton, BFormGroup, BFormInput, BForm } from "bootstrap-vue";

import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required, email } from "@validations";
configure({
  generateMessage: localize("fr", {
    messages: {
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
  },
  data() {
    return {
      userData: {
        email: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        lastName: "",
        civility: 0,
        phoneNumber: "",
        oldPassword: "",
      },
    };
  },
  created() {
    this.getUserInfoConnected({ updateState: false }).then((res) => {
      this.userData = res;
    });
  },
  methods: {
    ...mapActions(["getUserInfoConnected", "updateUser"]),
    updateUserEvent(e) {
      e.preventDefault();
      this.$refs.formMyAccount
        .validate()
        .then((success1) => {
          if (success1) {
            let dataUpdateUser = {
              id: this.connectedUser.id,
              phoneNumber: this.userData.phoneNumber,
              civility: this.userData.civility,
              firstName: this.userData.firstName,
              lastName: this.userData.lastName,
              email: this.userData.email,
            };
            this.updateUser({ user: dataUpdateUser });
            if (
              this.userData.oldPassword &&
              this.userData.password &&
              this.userData.password === this.userData.confirmPassword
            ) {
              let dataUpdatePassword = {
                oldPassword: this.userData.oldPassword,
                newPassword: this.userData.password,
                confirmPassword: this.userData.confirmPassword,
                userId: this.connectedUser.id,
                statusMessage: "",
              };
            }
            this.$nextTick(() => {
              if (
                this.$route.params.routeOrigine &&
                this.$route.params.routeOrigine !== ""
              ) {
                this.$tabs.close();
              } else {
                this.$tabs.close();
              }
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    cancel() {
      if (
        this.$route.params.routeOrigine &&
        this.$route.params.routeOrigine !== ""
      ) {
        this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
      } else {
        this.$tabs.close();
      }
    },
  },
  computed: {
    ...mapGetters(["connectedUser", "isLoadingUser"]),
  },
};
</script>
